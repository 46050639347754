import { Component, OnInit } from '@angular/core';
import { InitService } from './services/services/init.service';
import { MenuService } from './services/services/menu.service';
import { CoreProvider } from './services/services/core.provider';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'ngx-app',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(private readonly menuService: MenuService, public readonly core: CoreProvider, public readonly actRouter: ActivatedRoute) {}
  async ngOnInit(): Promise<void> {
    await this.menuService.updateMenuItems();
    // this.actRouter.queryParams.subscribe(param => {
    //   if (param['webview']) {
    //     environment.isMobileApp = true;
    //   }
    // })
    // await this.core.initService.checkAction();
  }
}
