/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SocSiemService } from './services/soc-siem.service';
import { AuthService } from './services/auth.service';
import { TeamsService } from './services/teams.service';
import { SlackService } from './services/slack.service';
import { CompanyDataService } from './services/company-data.service';
import { PersonalDataService } from './services/personal-data.service';
import { MeService } from './services/me.service';
import { HealthService } from './services/health.service';
import { TypeService } from './services/type.service';
import { EnvConfigService } from './services/env-config.service';
import { WorkspaceService } from './services/workspace.service';
import { MailConfigService } from './services/mail-config.service';
import { CampaignService } from './services/campaign.service';
import { MailTemplateService } from './services/mail-template.service';
import { LandingPageService } from './services/landing-page.service';
import { DepartmentService } from './services/department.service';
import { ProfileService } from './services/profile.service';
import { CampaignGroupService } from './services/campaign-group.service';
import { CampaignMailHeaderService } from './services/campaign-mail-header.service';
import { CampaignProfileService } from './services/campaign-profile.service';
import { CampaignEventService } from './services/campaign-event.service';
import { ResourceService } from './services/resource.service';
import { AttackSubtypeService } from './services/attack-subtype.service';
import { AlgorithmFeedService } from './services/algorithm-feed.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SocSiemService,
    AuthService,
    TeamsService,
    SlackService,
    CompanyDataService,
    PersonalDataService,
    MeService,
    HealthService,
    TypeService,
    EnvConfigService,
    WorkspaceService,
    MailConfigService,
    CampaignService,
    MailTemplateService,
    LandingPageService,
    DepartmentService,
    ProfileService,
    CampaignGroupService,
    CampaignMailHeaderService,
    CampaignProfileService,
    CampaignEventService,
    ResourceService,
    AttackSubtypeService,
    AlgorithmFeedService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
