import { Injectable } from '@angular/core';
import { AttackSubtypeService, CampaignGroupService, CampaignProfileService, CampaignService, CompanyDataService, EnvConfigService, LandingPageService, MailConfigService, MailTemplateService, MeService, PersonalDataService, ProfileService, ResourceService, SlackService, TeamsService, WorkspaceService } from '../api/services';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    public readonly workspace: WorkspaceService,
    public readonly envConf: EnvConfigService,
    public readonly profiles: ProfileService,
    public readonly groups: CampaignGroupService,
    public readonly mailTemplate: MailTemplateService,
    public readonly mailConfig: MailConfigService,
    public readonly campaign: CampaignService,
    public readonly campaignProfile: CampaignProfileService,
    public readonly landingPage: LandingPageService,
    public readonly me: MeService,
    public readonly resource: ResourceService,
    public readonly attackSubType: AttackSubtypeService,
    public readonly personalData: PersonalDataService,
    public readonly companyData: CompanyDataService,
    public readonly slack: SlackService,
    public readonly teams: TeamsService
  ) {
  }
}
